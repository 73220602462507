import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import residentialMortgagesMenu from '../../../../data/page-menus/residential-mortgages';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../../components/Icon/Icon';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const RenewMortgage: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`renewMortgage.seo.title`),
    },
    page: {
      title: t(`renewMortgage.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-12.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getTimeLine = (timeLineItems: any[]) =>
    timeLineItems.map((item, index) => (
      <div className="item" key={index}>
        <h2 className="h6">
          <Trans>{item.time}</Trans>
        </h2>
        <p key={index}>
          <Trans>{item.description}</Trans>
        </p>
      </div>
    ));

  return (
    <Layout options={layoutOptions} className="RenewMortgage">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="RenewMortgage__generic-gap">
              <p>
                <Trans i18nKey="renewMortgage.body" t={t} />
              </p>
            </div>
            <div className="RenewMortgage__generic-gap">
              <ButtonLink
                to={getPathFromRouteKey(
                  RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.RENEW_YOUR_MORTGAGE.FORM
                )}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{
                  isInline: true,
                  invertColors: true,
                }}
              >
                {t('renewMortgage.startRenewal.buttonLabel')}
              </ButtonLink>
            </div>
            <p>
              <Trans i18nKey="renewMortgage.body2" t={t} />
            </p>
            <div className="RenewMortgage__generic-gap timeLine">
              {getTimeLine(t('renewMortgage.timeLine', { returnObjects: true }))}
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={residentialMortgagesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.residentialMortgages`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(RenewMortgage);
